.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

body {
  font-family: "Poppins", sans-serif;
  background: #fafafa;
}

a,
a:hover,
a:focus {
  color: inherit;
  /* text-decoration: none; */
  /* transition: all 0.3s; */
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #3c4858;
  color: #fff;
  margin-left: -250px;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
}

.sidebar-header {
  background: #0e9b6d;
}

.sidebar-header h3 {
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.menu-open {
  background: #6d7fcc;
}

.nav-item:hover {
  /* color: #0e9b6d; */
  /* background: #fff; */
}

.items-menu {
  color: #fff;
  background: #0e9b6d;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header > span {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}
.side-menu {
  height: calc(100vh - 130px);
  padding-left: 10;
  overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: #5466b3;
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  /* background: #3a4c99; */
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  padding: 20px;
  margin-left: 0;
  height: 100vh;
}

@media only screen and (max-width: 500px) {
  body {
    overflow: hidden;
  }

  .content.is-open {
    margin-left: 100%;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open > .sidebar-header span {
    display: unset;
    position: absolute;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}

.side-nav-style {
  background-color: #fff;
  border-radius: 10 !important;
  padding: 10;
  margin-bottom: 20;
}
th.th-color {
  color: #0e9b6d;
  border-top: 0;
  border-width: 0;
  font-weight: 500;
}

td.texta {
  vertical-align: middle;
  pointer-events: none;
}

.shadow.p-3.pt-4.mb-5.bg-white.card {
  border-radius: 12px;
}

.card-header.chead {
  background: transparent;
}

.card-footer.cfoot {
  background: transparent;
}

.card label {
  font-size: 0.8571em;
  margin-bottom: 5px;
  color: #9a9a9a;
  font-weight: 300;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.card .card-footer .btn {
  margin: 0;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
.btn-round {
  border-width: 1px;
  border-radius: 30px;
  padding-right: 23px;
  padding-left: 23px;
}

.btn-primary {
  background-color: #0e9b6d;
  border-color: #0e9b6d;
}

button.btn-round.btn.btn-primary.new-prime {
  background: #0e9b6d;
  border-color: #0e9b6d;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
}

primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

a.create-btn {
  background: #0e9b6d;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  border-radius: 6px;
  padding: 10px 15px;
}

.modal-content.loader {
  background-color: transparent;
  border: 0;
}
h5.question-heading.card-title {
  text-transform: capitalize;
}

td.texta.dropdown {
  cursor: pointer;
  color: #0e9b6d;
  text-decoration-line: underline;
  pointer-events: auto;
}

div.create-btn {
  background: #0e9b6d;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  border-radius: 6px;
  padding: 10px 15px;
  cursor: pointer;
}

.invalid-feedback.error-text {
  display: block;
}

.shadow.border-4.pl-2.row-list-container.card {
  height: 101px;
  overflow: auto;
  border-radius: 8px;
}

.row-list.form-group {
  height: 80px;
  overflow: auto;
  border-radius: 8px;
}

.inline-list-border {
  margin-bottom: 20px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0e9b6d;
  border-color: #0e9b6d;
}

.page-link{
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color:#0e9b6d;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

tr.block {
  background: rgba(255, 180, 0,0.15)
}
/* .page-link:hover,:active {
  box-shadow: none;
  color:#0e9b6d;
  
} */

/* a.page-link:focus{
  box-shadow: 0 0 0 4px rgb(40 167 69 / 35%);
} */